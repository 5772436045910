@font-face {
    font-family: "DDIN";
    src: local("D-DIN"),
    url("../fonts/D-DIN.ttf") format("truetype");
}
@font-face {
    font-family: "DDINBold";
    src: local("D-DIN-Bold"),
    url("../fonts/D-DIN-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "DDINItalic";
    src: local("D-DIN-Italic"),
    url("../fonts/D-DIN-Italic.ttf") format("truetype");
}

::-webkit-scrollbar {
    height: 8px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: var(--main-focus-color);
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: var(--background-secondary-color);
    border-radius: 20px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-family: 'DDIN', sans-serif;
}

html {
    background: var(--background-color) 0% 0% no-repeat padding-box;
}

a, a:visited, a:link, a:active {
    text-decoration: none;
    outline:0;
}

.fade {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}