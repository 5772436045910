.button-reverto {
    background-color: var(--main-focus-color);
    font: normal normal normal 18px/20px 'DDIN';
    letter-spacing: 0px;
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.button-reverto:hover {
    box-shadow: 0px 0px 4px var(--main-focus-color);
    cursor: pointer;
}