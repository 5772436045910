#TitleLayout{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0px;
}

#TitleLayoutTwoLine {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 10px 0px;
}

#TitleLayout p.title, #TitleLayoutTwoLine p.title{
    font: normal normal bold 18px/20px 'DDINBold';
    letter-spacing: 0.8px;
    color: #FFFFFF;
    overflow: hidden;
}

#TitleLayout p.subtitle{
    font: normal normal normal 14px/16px 'DDIN';
    letter-spacing: 0px;
    color: #8B90A0;
    margin-left : 10px;
    /* margin-top: 4px; */
}

#TitleLayoutTwoLine p.subtitle{
    font: normal normal normal 14px/16px 'DDIN';
    letter-spacing: 0px;
    color: #8B90A0;
}
