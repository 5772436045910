.container-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.label-input {
    font: italic normal normal 15px/20px 'DDINItalic';
    color: var(--text-secondary-color, #8B90A0);
}

.textfield-reverto{
    display: flex;
    flex-direction: column;
    border-radius : 5px;
    font: normal normal normal 18px/20px 'DDIN';
    border : 1px solid var(--text-secondary-color, #8B90A0);
    background: var(--background-container-color, #2B2F3A);
    color: var(--text-secondary-color, #8B90A0);
    padding: 25px;
    outline: none;
}

.textfield-reverto:focus, .textfield-reverto:active {
    border : 1px solid var(--main-focus-color);
    box-shadow : #2b2f3a 0 0 0 0.1rem;
    outline: none;
}
