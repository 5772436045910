.drawerMenuMobile .MuiDrawer-paper {
    min-width: 0% !important;
}

#RButtonNavBar {
    position: fixed;
    border : 2px dashed #34AE9B;
    bottom : 90px;
    right : 20px;
    z-index : 1000;
    width: 100px;
    height: 100px;
}