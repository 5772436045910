.user-bar {
    background: transparent linear-gradient(270deg, var(--main-secondary-color) 0%, var(--main-focus-color) 100%) 0% 0% no-repeat padding-box;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    color : var(--text-primary-color);
    width: 100%;
}

.user-bar-link {
    color: var(--text-primary-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font: normal normal bold 18px/20px 'DDINBold';
    letter-spacing: 0.56px;
    gap: 10px;
}

.user-bar-logo {
    height : 40px;
}

.user-licences {
    flex : 24%;
    font: normal normal bold 20px/22px 'DDINBold';
    letter-spacing: 0.8px;
}

.user-licences:hover {
    cursor: pointer;
}


.user-research {
    flex : 48%;
}

.user-profil-button:hover {
    cursor: pointer;
}

.user-profil-button {
    font: normal normal bold 14px/16px 'DDINBold';
    width: 40px;
    height: 40px;
}

.user-profil-button .MuiAvatar-root {
    border : 2px solid var(--main-focus-color);
    font: normal normal bold 12px/14px 'DDINBold';
    background-color: var(--background-secondary-color);
}


