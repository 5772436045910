#main-menu {
    background: #2B2F3A;
    min-height: 100%;
    min-width: 50px;
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    -webkit-box-align:start;
    -webkit-box-pack:center;
}

.main-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s;
    margin: 90px 0px 120px 0px;
}

#logo-open svg{
    width: 50px;
    transition: all .5s;
}

#logo-close svg{
    width: 20px;
    transition: all .5s;
}

#logo-open {
    animation: logo-opening .5s normal forwards;
}
@keyframes logo-opening {
    0% {
        margin: 90px 0px 120px 0px;
    }
    100% {
        margin: 90px 90px 120px 90px;
    }
}

#logo-close {
    animation: logo-closing .5s normal forwards;
}
@keyframes logo-closing {
    0% {
        margin: 90px 90px 120px 90px;
    }
    100% {
        margin: 90px 0px 120px 0px;
    }
}

.support-button, .support-button-close {
    position : absolute;
    bottom: 30px;
    border: 2px solid #58c9b7;
    background: #393C45 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 2px #00000029;
    border: 2px solid #58C9B7;
    border-radius: 5px;
    opacity: 1;
    color : #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.support-button svg, .support-button-close svg {
    width: 20px;
    height: 20px;
}

.support-button {
    margin: 0px 20px;
    padding: 10px 30px;
    gap : 10px;
    transition: all .5s;
    min-width: calc(100% - 40px);
}

.support-button:hover {
    cursor: pointer;
}

.support-button span{
    font: normal normal 16px/18px 'DDIN';
    transition: all .5s;
}

.support-button-close {
    margin: 0px 5px;
    width: 36px;
    height: 36px;
    min-width: calc(100% - 10px);
}

.support-button-close span {
    visibility: hidden;
    width : 0px;
}

.version-site-web-container {
    position : absolute;
    bottom: 0;
    margin: 0px 5px;
    min-width: calc(100% - 10px);
    font: normal normal 12px/14px 'DDIN';
    color : #7F8079;
    text-align: center;
}

.retract-icon {
    color: #FFF;
    position: absolute;
    top: 20px;
    right: 12px;
    width: 24px;
    height: 24px;
}

.retract-icon:hover {
    cursor: pointer;
    color: #58C9B7;
}