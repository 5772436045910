.confirm-dialog-container {
  position : relative;
  top : 20%;
  padding : 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width : 450px;
  gap: 10px;
  margin: auto;
  background-color: var(--background-container-color);
  color: var(--text-primary-color);
  border-radius: 5px;
}

.confirm-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.confirm-dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
}