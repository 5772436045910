* {
    --main-focus-color : #329E8C;
    --main-focus-hover-color : #34AE9B;
    --main-focus-light-color : #58C9B7;

    --main-secondary-color : #0285A3;
    --main-secondary-hover-color : #0295B2;

    --main-dark-color : #0A4350;
    
    --warning-color : #F29849;
    --danger-color : #FF5E5E;

    --background-color : #22252D;
    --background-secondary-color : #393C45;
    --background-container-color : #2B2F3A;
    
    --text-primary-color : #FFF;
    --text-primary-hover-color : #F2F2F2;

    --text-secondary-color : #8B90A0;

    --input-text-color : #DBDBE8;

    /* Fonts */
    --main-text-font-family: 'DDIN', sans-serif;
    --main-text-font-size: 20px;
    --main-text-line-height: normal;
    --main-text-font-weight: 400;
    --main-text-font-style: normal;
    --title-text-font-family: 'DDINBold', sans-serif;
    --title-text-font-size: 20px;
    --title-text-line-height: normal;
    --title-text-font-weight: 400;
    --title-text-font-style: normal;
    --subtitle-text-font-family: 'DDIN', sans-serif;
    --subtitle-text-font-size: 20px;
    --subtitle-text-line-height: normal;
    --subtitle-text-font-weight: 400;
    --subtitle-text-font-style: normal;
    --main-bold-text-font-family: 'DDINBold', sans-serif;
    --main-bold-text-font-size: 20px;
    --main-bold-text-line-height: normal;
    --main-bold-text-font-weight: 400;
    --main-bold-text-font-style: normal;
    --information-text-font-family: 'DDIN', sans-serif;
    --information-text-font-size: 15px;
    --information-text-line-height: normal;
    --information-text-font-weight: 400;
    --information-text-font-style: normal;
    --legend-text-font-family: 'DDIN', sans-serif;
    --legend-text-font-size: 15px;
    --legend-text-line-height: normal;
    --legend-text-font-weight: 400;
    --legend-text-font-style: normal;
}