.login-container {
    position: relative;
}

.login-inactive {
    display : none;
}

.subscribe-inactive {
    display : none;
}


.background-login-container{
    padding: 0px;
    background: transparent url('asset/default/LoginImg.png') no-repeat padding-box center;
    /* background-size: contain; */
    background-size: cover;
    box-shadow: 0px 0px 0px 0px #0000001A;
    transition: transform 1s;
}

.login-container-login-filter {
    background: transparent linear-gradient(180deg, rgba(2, 133, 163, 0.7) 0%, rgba(50, 158, 140, 0.7) 100%) 0% 0% no-repeat padding-box;
    min-width: 100%;
    min-height: 100%;
}

.background-subscribe-container{
    padding: 0px;
    background: transparent url('asset/default/LoginImg.png') no-repeat padding-box center;
    /* background-size: contain; */
    background-size: cover;
    box-shadow: 0px 0px 0px 0px #0000001A;
}




.login-container-middle-logo {
    position : absolute;
    width: 200px;
    height: 200px;
    padding: 15px;
    background-color: rgba(34, 37, 45, 1);
    border-radius: 50%;
    transition: transform 1s;
}

.login-container-middle-logo:hover {
    cursor: pointer;
    transform: rotate3d(0, -1, 0, 180deg);
}

.login-container-middle-logo:hover .background-login-container{
    cursor: pointer;
    transform: rotate(180deg);
}

.login-container-middle-logo img {
    width: 170px;
    height: 170px;
    background-color: rgba(43, 47, 58, 1);
    border-bottom: 1px solid var(--main-focus-color);
    border-radius: 50%;
}

.login-form-container {
    width: 100%;
    height: 100%;
    display : flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width : 363px;
}


.login-form-button{
    background: var(--background-container-color, #2B2F3A);
    border: none;
    font: normal normal normal 18px/20px 'DDIN';
    letter-spacing: 0px;
    color: #FFFFFF;
    border-bottom: 1px solid var(--main-focus-color);
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    width: 100%;
    text-transform: uppercase;
}

.login-form-button:hover{
    background: var(--background-secondary-color, #2B2F3A);
}

.OAlert{
    font: normal normal normal 12px/18px 'DDIN' !important;
    text-align: justify;
    width: 100%;
}

.OAlertTitle{
    font: bold normal normal 16px/20px 'DDINBold' !important;
}

.link-change-login {
    font: normal normal normal 20px/22px 'DDIN';
    letter-spacing: 0px;
    color: rgba(139, 144, 160, 1);
    margin-top: 10px;
    float: right;
}

.link-password-forgot {
    font: normal normal normal 16px/18px 'DDIN';
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    float: right;
    margin-bottom: 40px;
}

.link-change-login:hover, .link-password-forgot:hover {
    cursor: pointer;
}