#RNavItem {
    position : relative;
    padding : 8px;
    margin : 2px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#RNavItem.close{
    gap: 0px;
}

#RNavItem::before {
    content: "";
    padding: 0px 10px;
}

#RNavItem.close::before{
    gap: 0px;
    padding: 0px;
}

#RNavItem i svg {
    float: left;
    color : #8B90A0;
    margin-left: min(0px, 50px);
    width: 20px;
    height: 20px;
}

#RNavItem p {
    color : #8B90A0;
    font: normal normal 1em 'DDIN';
    min-width: 200px;
}

#RNavItem:hover {
    background-color: #393C45;
    cursor: pointer;
    text-decoration: none;
}

#RNavItem:hover p, #RNavItem:hover i svg {
    color : #FFF;
}

#RNavItem.active {
    background-color: #393C45;
}

#RNavItem.active p, #RNavItem.active i svg {
    color : #FFF;
}

#RNavItem.active:hover {
    cursor: default;
}