.main-container {
    display: flex;
    flex-direction: row;
    overflow: hidden
}

.navbar-container {
    flex: 1;
    /* min-width : 280px; */
    overflow-y: auto;
    overflow-x: hidden;
    animation: navbar-opening .5s normal forwards;
}

@keyframes navbar-opening {
    0% {
        max-width: 50px;
    }
    100% {
        max-width: 280px;
    }
}

.navbar-container-closed {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    animation: navbar-closing .5s normal forwards;
}

@keyframes navbar-closing {
    0% {
        max-width: 280px;
    }
    100% {
        max-width: 50px;
    }
}

.global-view-container {
    padding: 20px 30px;
    overflow-y: auto;
    overflow-x: hidden;
}